/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import * as React from "react";
import { Link } from "gatsby";
import { FeatureItemModel, FeatureModel } from "../../models/FeatureModel";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MediaQuery from "react-responsive";

type DataProps = {
  feature: FeatureModel;
};

const FeatureItem = ({ feature }: DataProps): JSX.Element => {
  const block = feature.featureArticle?.blocks?.block ?? [];
  const contents: FeatureItemModel[] = [];
  for (let i = 0; i < block.length; i++) {
    contents.push(...block[i].contents);
  }
  const featureContents = contents;
  const slug = feature.featureArticle?.slug ?? "";
  const postType = feature.featureArticle?.contentTypeName;
  const link =
    postType === "lab" ? `/${postType}/work/${slug}` : `/${postType}/${slug}`;

  if (!featureContents || featureContents.length === 0) {
    return <React.Fragment />;
  }

  const targets = featureContents.filter(
    (v) => v.type === "picture" || v.type === "movie" || v.type === "movie_url"
  );

  const target =
    targets.find((v) => v.usingFeature === true) ??
    (targets.length > 0 ? targets[0] : null);

  if (!target) {
    return <React.Fragment />;
  }

  if (target.type === "picture") {
    const src = target.picture.localFile.childImageSharp;
    const srcMobile = target.pictureMobile
      ? target.pictureMobile.localFile.childImageSharp
      : src;
    const image = getImage(src);
    const imageMobile = getImage(srcMobile);
    if (!image || !imageMobile) {
      return <React.Fragment />;
    }

    const styles = css`
      width: 100%;
      /* 
      object-fit: cover;
      height: calc(100vh - 125px);
      @media (min-width: 767px) {
        height: calc(100vh - 100px);
      }
      @media (max-height: 800px) {
        height: 100vh;
      }
      */
    `;

    return (
      <Link to={link}>
        <MediaQuery maxWidth={767}>
          <GatsbyImage
            image={imageMobile}
            alt=""
            css={styles}
            objectFit="contain"
          />
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <GatsbyImage image={image} alt="" css={styles} objectFit="contain" />
        </MediaQuery>
      </Link>
    );
  }

  if (target.type === "movie_url") {
    const mYoutube = target.movieUrl.match(/^.+youtube.+\/watch\?v=(.+)/);
    if (mYoutube) {
      // youtube
      const videoID = mYoutube[1];

      return (
        <div>
          <div
            css={css`
              width: 100%;
              height: calc(100vh - 125px);
              @media (min-width: 767px) {
                height: calc(100vh - 100px);
              }
              @media (max-height: 800px) {
                height: 100vh;
              }
            `}
          >
            <div className="video-background">
              <iframe
                src={
                  "https://www.youtube.com/embed/" +
                  videoID +
                  "?autoplay=1&mute=1"
                }
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </div>
        </div>
      );
    }

    const mVimeo = target.movieUrl.match(/^.+vimeo.+\/(.+)$/);
    if (mVimeo) {
      // vimeo
      const videoID = mVimeo[1];
      return (
        <div>
          <div
            css={css`
              width: 100%;
              height: calc(100vh - 125px);
              @media (min-width: 767px) {
                height: calc(100vh - 100px);
              }
              @media (max-height: 800px) {
                height: 100vh;
              }
            `}
          >
            <div className="video-background">
              <iframe
                src={`https://player.vimeo.com/video/${videoID}?h=a29593d697&autoplay=1&loop=1&title=0&byline=0&portrait=0`}
                allow="autoplay; fullscreen; picture-in-picture"
              ></iframe>
            </div>
          </div>
        </div>
      );
    }
  }

  if (target.type === "movie") {
    const movie = target.movie ? target.movie.guid : null;
    const movieMobile = target.movieMobile ? target.movieMobile.guid : movie;
    if (!movie || !movieMobile) {
      return <React.Fragment />;
    }

    const styles = css`
      width: 100%;
      /*
      height: calc(100vh - 125px);
      @media (min-width: 767px) {
        height: calc(100vh - 100px);
      }
      @media (max-height: 800px) {
        height: 100vh;
      }
      */
    `;

    return (
      <Link to={link}>
        <div css={styles}>
          <MediaQuery maxWidth={767}>
            <video
              src={movieMobile}
              style={{ width: "100%", height: "100%", display: "block" }}
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <video
              src={movie}
              style={{ width: "100%", height: "100%", display: "block" }}
              muted
              autoPlay
              loop
              playsInline
            ></video>
          </MediaQuery>
        </div>
      </Link>
    );
  }

  return <React.Fragment />;
};

export default FeatureItem;
