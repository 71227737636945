import * as React from "react";
import Slider, { Settings } from "react-slick";
import { FeatureModel } from "../../models/FeatureModel";
import FeatureItem from "./feature-item";

type DataProps = {
  features: FeatureModel[];
  onChange?: (color: "white" | "black") => void;
};

const Feature = ({ features, onChange }: DataProps): JSX.Element => {
  const images: JSX.Element[] = [];
  for (let i = 0; i < features.length; i++) {
    const feature = features[i];
    images.push(<FeatureItem key={i} feature={feature} />);
  }

  const settings: Settings = {
    infinite: true,
    // fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 2,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    swipe: false,
    beforeChange: (current, next) => {
      const color = features[next].featureHeaderColor;
      if (onChange && color) {
        onChange(color);
      }
    },
  };
  return <Slider {...settings}>{images}</Slider>;
};

export default Feature;
